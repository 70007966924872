<template>
  <div>

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4>Activities Index</h4>
      <!-- Create Button Organization -->
      <router-link :to="{ name: 'ActivityNew',
                   params: {
                      activityCount: highestReportCol()
                   }}"
                   tag="button"
                   type="button"
                   class="btn btn-success">Create Activity</router-link>
    </div>
    <div v-if="activities">

      <!-- Organization Index -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="rainbow-background"></div>
            <b-checkbox id="activities-archived" v-model="filters.is_archived" v-on:input="getActivities(currentPage, perPage)" switch>
              View Archived Activities
            </b-checkbox>
            <!-- Table Headers and Search Bar -->
            <ransack-filtering :headerWidth='42' :searchParam="searchParam" :tableHeaders="headers" @onChange="setFilters"/>

            <div class="table-responsive">
              <table class="card-table table">
                <tbody>
                <tr v-for="activity in activities" :key="activity.id" :id="activity.id">
                  <td class="text-left" style="width: 42%">
                    {{ activity.title }}
                  </td>
                  <td class="text-left" >
                    {{ getReportOrderCol(activity) }}
                  </td>
                  <td class="w-50">
                    <div class="btn-group float-right">
                      <router-link
                        :to="{ name: 'ActivityEdit',
                        params: { id: activity.id,
                        activityCount: highestReportCol()} }"
                        tag="button" class="btn btn-secondary"
                        type="button">
                        Edit
                      </router-link>
                      <router-link
                        :to="{ name: 'ActivityShow',
                        params: { id: activity.id,} }"
                                   tag="button"
                                   class="btn btn-primary"
                                   type="button">
                        Show
                      </router-link>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!-- Pagination -->
            <pagination :totalRows="totalRows" :page="currentPage" :numOfRecords="perPage" @onChange="onChange"/>

          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-else/>
  </div>
</template>

<script>

import { ActivityService } from '@/common/services/activity.service';
import { FilterService } from '@/common/services/filter.service';
import Pagination from '@/common/components/pagination.vue';
import RansackFiltering from '@/common/components/ransack-filtering.vue';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'ActivityIndex',
  components: {
    Pagination,
    RansackFiltering,
    LoadingOverlay,
  },
  data() {
    return {
      activities: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalRecords: 0,
      headers: ['Title', 'Report Order'],
      searchParam: 'title_cont',
      filters: FilterService.defaultData(),
    };
  },
  created() {
    this.filters.s = "report_order asc";
    this.getActivities(this.currentPage, this.perPage);
  },
  methods: {
    getActivities(page, perPage) {
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      ActivityService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.activities = response.data;
      });
    },
    onChange(page, perPage) {
      this.getActivities(page, perPage);
    },
    setFilters(filters) {
      this.filters = filters;
      this.getActivities(this.currentPage, this.perPage);
    },
    highestReportCol() {
      return this.totalRows;
    },
    getReportOrderCol(activity) {
      if (!activity.report_order || activity.report_order === 0) {
        return 'N/A';
      }
      return activity.report_order;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/activities";
</style>
