import ApiService from './api.service';

export const ActivityService = {
  query(params) {
    return ApiService.query('activities', {
      params,
    });
  },
  get(slug) {
    return ApiService.get('activities', slug);
  },
  create(params) {
    return ApiService.post('activities', { activity: params });
  },
  update(slug, params) {
    return ApiService.update('activities', slug, { activity: params });
  },
  destroy(slug) {
    return ApiService.delete(`activities/${slug}`);
  },
};

export default ActivityService;
